/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RefreshSavingsScreensRequest} from '@cash-web/protos/squareup/cash/app/refresh_savings_screens.pb'
import {RefreshSavingsScreensResponse} from '@cash-web/protos/squareup/cash/app/refresh_savings_screens.pb'


export const refreshSavingsScreens = (request:RefreshSavingsScreensRequest, options?: CashRequestOptions) => post<RefreshSavingsScreensRequest, RefreshSavingsScreensResponse>("/2.0/cash/refresh-savings-screens", request, {...options, rejectOnError: true })