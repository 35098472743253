/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {HasLightningPaymentsRequest} from '@cash-web/protos/squareup/cash/cryptosparky/api/public.pb'
import {HasLightningPaymentsResponse} from '@cash-web/protos/squareup/cash/cryptosparky/api/public.pb'


export const hasLightningPayments = (request:HasLightningPaymentsRequest, options?: CashRequestOptions) => post<HasLightningPaymentsRequest, HasLightningPaymentsResponse>("/2.0/cash/has-lightning-payments", request, {...options, rejectOnError: true })