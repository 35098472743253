/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetContactAliasHistoryRequest} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'
import {GetContactAliasHistoryResponse} from '@cash-web/protos/squareup/cash/janus/api/AccountHolder.pb'


export const getContactAliasHistory = (request:GetContactAliasHistoryRequest, options?: CashRequestOptions) => post<GetContactAliasHistoryRequest, GetContactAliasHistoryResponse>("/2.0/cash/get-contact-alias-history", request, {...options, rejectOnError: true })