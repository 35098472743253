import React, { ReactNode, useContext } from 'react'

import { ThemeProvider, useTheme } from '@emotion/react'
import whatInput from 'what-input'

import { ArcadeIconProvider } from '@arcade/web/icons/react'

import { getArcadeColorsByThemeVariant } from '../theme/arcadeColors'
import border from '../theme/border'
import breakpoints from '../theme/breakpoints'
import { addOpacity, Colors, getColorsByThemeVariant, ThemeVariant } from '../theme/colors'
import getDropShadows from '../theme/dropShadows'
import maxWidths from '../theme/maxWidths'
import motion from '../theme/motion'
import selectors from '../theme/selectors'
import spacing from '../theme/spacing'
import typography, { pxToRem, remToPx, remToPxRaw } from '../theme/typography'
import weights from '../theme/weights'
import { ArcadeComponentMigrationProvider } from './ArcadeComponentMigration/ArcadeComponentMigrationProvider'
import GlobalStyles from './GlobalStyles'

const ESC_CODE = 27
whatInput.ignoreKeys([ESC_CODE])

export type ImageUrlHandler = (args: { url: string; width: number }) => string | undefined
interface Props {
  themeVariant?: ThemeVariant
  darkMode: boolean
  children: React.ReactNode
  bodyBackground?: Colors
  /**
   * Arcade component migration feature flags to be passed down to the ArcadeMigrationProvider
   */
  featureFlags?: Record<string, string>
  /**
   * We are migrating to a new font system, Cash Sans.
   * This prop allows us to opt into using the new font system.
   */
  shouldUseCashSans?: boolean
  /**
   * Currently required for the arcade Avatar component to replace the image URL with a CDN URL.
   * This should be used in all components that accepts an image URL.
   */
  imageUrlHandler?: ImageUrlHandler
}

const ThemedArcadeIconProvider = ({ children }: { children: ReactNode }) => {
  const theme = useTheme()
  return (
    <ArcadeIconProvider
      variants={{
        prominent: theme.colors.iconProminent,
        standard: theme.colors.icon,
        subtle: theme.colors.iconSubtle,
        'extra-subtle': theme.colors.iconExtraSubtle,
        inverse: theme.colors.iconInverse,
        disabled: theme.colors.iconDisabled,
        danger: theme.colors.iconDanger,
        warning: theme.colors.iconWarning,
        success: theme.colors.iconSuccess,
        info: theme.colors.iconInfo,
      }}
    >
      {children}
    </ArcadeIconProvider>
  )
}

export const CashDesignSystemContext = React.createContext({
  imageUrlHandler: undefined as ImageUrlHandler | undefined,
  hasBeenProvidedOnce: false,
  darkMode: false,
})

/**
 * Use to change the theme variant of a component subtree.
 * To change the style at the root of an application, prefer `themeVariant` on the `CashDesignSystemProvider`.
 */
export const ThemeVariantProvider = ({
  themeVariant,
  children,
}: {
  children: React.ReactNode
  themeVariant: ThemeVariant
}) => {
  const { darkMode, hasBeenProvidedOnce } = useContext(CashDesignSystemContext)

  if (!hasBeenProvidedOnce) {
    throw new Error('`ThemeVariantProvider` must be rendered within a `CashDesignSystemProvider`')
  }

  const arcadeColors = getArcadeColorsByThemeVariant({ themeVariant, darkMode })
  const colors = getColorsByThemeVariant({ themeVariant, darkMode })
  const dropShadows = getDropShadows(darkMode)

  return (
    <ThemeProvider
      theme={{
        darkMode,
        arcadeColors,
        border,
        colors,
        addOpacity,
        maxWidths,
        motion,
        typography,
        weights,
        dropShadows,
        breakpoints,
        pxToRem,
        remToPx,
        remToPxRaw,
        selectors,
        spacing,
      }}
    >
      <ThemedArcadeIconProvider>{children}</ThemedArcadeIconProvider>
    </ThemeProvider>
  )
}

const CashDesignSystemProvider: React.FC<Props> = ({
  children,
  darkMode,
  bodyBackground = 'background',
  themeVariant = 'default',
  featureFlags = {},
  shouldUseCashSans = false,
  imageUrlHandler,
}) => {
  const { hasBeenProvidedOnce } = useContext(CashDesignSystemContext)

  if (hasBeenProvidedOnce) {
    // Tip: To override a theme variant in a subtree of your application, use the `ThemeVariantProvider` directly.
    throw new Error('The `CashDesignSystemProvider` cannot be provided more than once')
  }

  // Note: The `what-import` module has a side effect, must include this in imported code to ensure its bundled.
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  whatInput

  return (
    <CashDesignSystemContext.Provider value={{ imageUrlHandler, darkMode, hasBeenProvidedOnce: true }}>
      <ThemeVariantProvider themeVariant={themeVariant}>
        <GlobalStyles bodyBackground={bodyBackground} shouldUseCashSans={shouldUseCashSans} />
        <ArcadeComponentMigrationProvider featureFlags={featureFlags}>{children}</ArcadeComponentMigrationProvider>
      </ThemeVariantProvider>
    </CashDesignSystemContext.Provider>
  )
}

export default CashDesignSystemProvider
