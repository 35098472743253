/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {Get1099IntStatementsRequest} from '@cash-web/protos/squareup/cash/app/get_1099_int_statements.pb'
import {Get1099IntStatementsResponse} from '@cash-web/protos/squareup/cash/app/get_1099_int_statements.pb'


export const get1099IntStatements = (request:Get1099IntStatementsRequest, options?: CashRequestOptions) => post<Get1099IntStatementsRequest, Get1099IntStatementsResponse>("/2.0/cash/get1099-int-statements", request, {...options, rejectOnError: true })