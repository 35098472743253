import type { CdnInfo } from 'typedefs/common/CdnInfo'

import { Campaign } from '@cash-web/protos/squareup/cash/cdpproxy/api/campaign.pb'
import type { MessageContext } from '@cash-web/protos/squareup/cash/cdpproxy/api/message_context.pb'
import { OperatingSystemName } from '@cash-web/protos/squareup/cash/cdpproxy/api/operating_system.pb'
import { redactTokensRecursive } from '@cash-web/shared/util-redaction'
import { uuidv4 } from '@cash-web/shared-util-formatting'
import { getDeviceInfo } from '@cash-web/shared-util-user-agent'
import type { Event } from '@squareup/customer-data-events-web'

// Declaring this here to avoid type error for the 'cdn' property.
// Most apps will have this already defined from the OverridableTemplateVariables.d.ts
// however apps like help center do not use template variables so that typing will not be applicable.
// The code will fallback to just being undefined in that case.
declare global {
  interface Window {
    cdn: CdnInfo
  }
}

interface logEventParams {
  applicationName?: string
  event: Event
  region?: string
  customerToken?: string
  hostName?: string
}

export interface Packet {
  context: MessageContext
  track: {
    event_name: string
    properties: string
  }
  message_uuid: string
  timestamp_since_epoch_millis: number
  customer_token?: string
}

export interface AnalyticsRequest {
  analytics_messages: Packet[]
}

export async function logEvent({ applicationName, event, region, customerToken, hostName }: logEventParams) {
  const eventContext = getAnalyticsEventContext(applicationName, region)
  redactTokensRecursive(event)
  redactTokensRecursive(eventContext)

  const packet: Packet = {
    context: eventContext,
    track: {
      event_name: event.name,
      properties: JSON.stringify(event.parameters),
    },
    message_uuid: uuidv4(),
    timestamp_since_epoch_millis: Date.now(),
    customer_token: customerToken,
  }

  // Defer import until client side render
  const { post } = await import('@cash-web/shared-util-fetch')
  // Note: the app not necessarily sitting in the same domain, hostname will need to be configurable
  const endpoint = `${hostName ?? ''}/2.0/cash/web/cdp`
  await post(endpoint, { analytics_messages: [packet] } as AnalyticsRequest, { keepalive: true })
}

// Gets the referrer as a Location object
function getReferrer(): HTMLAnchorElement | undefined {
  if (document.referrer) {
    const parser = document.createElement('a')
    parser.href = document.referrer
    return parser
  }
  return undefined
}

/**
 * We need to append 'Web' to iOS and Android OS to differentiate between Native Android/iOS events
 * Defaults to 'Web' for non-major browsers
 **/
export function getOperatingSystemName(name?: typeof OperatingSystemName | string) {
  // The string should be uppercase and spaces replaced with underscores
  const normalizeString = name?.toString().toUpperCase().split(' ').join('_')
  switch (normalizeString) {
    case OperatingSystemName.ANDROID:
      return OperatingSystemName.ANDROID_WEB
    case OperatingSystemName.IOS:
      return OperatingSystemName.IOS_WEB
    default: {
      const osName = OperatingSystemName[normalizeString as OperatingSystemName]
      return osName ? osName : OperatingSystemName.WEB
    }
  }
}

export function getUtmParams(location: Location): Campaign {
  const params = new URL(location.href).searchParams
  return {
    source: params.get('utm_source') || '',
    medium: params.get('utm_medium') || '',
    name: params.get('utm_name') || '',
    term: params.get('utm_term') || '',
    content: params.get('utm_content') || '',
  }
}

export function getAnalyticsEventContext(applicationName?: string, region?: string): MessageContext {
  const { browser, device, os } = getDeviceInfo()
  const location = window.location
  const referrer = getReferrer()

  return {
    device: {
      model: device.model,
      manufacturer: device.vendor,
      type: device.type,
      screen_density_dpi: window.devicePixelRatio.toString() || '1',
      screen_height: window.screen.height,
      screen_width: window.screen.width,
    },
    operating_system: {
      name: getOperatingSystemName(os.name),
      version: os.version || 'unknown',
    },
    browser: {
      height: window.innerHeight,
      width: window.innerWidth,
      major_version: browser.major,
      name: browser.name,
      version: browser.version,
    },
    locale: navigator.language,
    user_agent: navigator.userAgent,
    location: {
      country: region,
    },
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    page: {
      path: location.pathname,
      referrer: referrer?.toString(),
      title: '', // This is redacted to prevent PII from leaking
      url: location.href,
      search: location.search,
    },
    campaign: getUtmParams(location),
    application: {
      name: applicationName || 'cash-web',
      // version must be semver compliant where patch is optional, on web the version is a gitsha
      // so we default to 1.0 and use the prerelease identifer in the semver spec to include the gitsha
      version: window.cdn?.version ? `1.0-${window.cdn.version}` : undefined,
      namespace: 'cash-web',
    },
  }
}
